import { Validators } from '@angular/forms';

export const AppConfig = {

  formDetails: {
    courseId: {
      key: 'cid',
      label: 'Select the course',
      placeholder: 'Select course',
      validation: [
        Validators.required
      ],
      errorMessage: {required: 'Course is required'}
    },
    firstName: {
      key: 'first_name',
      label: 'First Name',
      placeholder: 'First Name',
      validation: [
        Validators.required,
        Validators.pattern('^[a-zA-Z\'. ]+$'),
        Validators.maxLength(100),
        Validators.minLength(3)
      ],
      errorMessage: {required: 'First Name is required', invalid: 'First Name is invalid'}
    },
    lastName: {
      key: 'last_name',
      label: 'Last Name',
      placeholder: 'Last Name',
      validation: [
        Validators.required,
        Validators.pattern('^[a-zA-Z ]+$'),
        Validators.maxLength(100)
      ],
      errorMessage: {required: 'Last name is required', invalid: 'Last Name is invalid'}
    },
    emailId: {
      key: 'mail',
      label: 'Email ID',
      placeholder: 'Enter Email ID',
      validation: [
        Validators.required,
        Validators.email
      ],
      errorMessage: {required: 'Email ID is required', invalid: 'Invalid Email Id'}
    },
    password: {
      key: 'password',
      label: 'Password',
      placeholder: 'Enter Password',
      validation: [
        Validators.required
      ],
      errorMessage: {required: 'Password is required', invalid: 'Invalid Password'}
    },
    countryCode: {
      key: 'country_code',
      label: '',
      placeholder: '',
      validation: [],
      errorMessage: { }
    },
    acceptTerms: {
      key: 'consent_terms',
      label: 'I accept the Terms and conditions of Athena Global Education.',
      validation: [
        Validators.requiredTrue,
      ],
      errorMessage: {required: 'Terms and condition is required'}
    },
    countryContactCode: {
      key: 'calling_code',
      label: '',
      placeholder: '',
      validation: [],
      errorMessage: { }
    },
    recoveryEmailId: {
      key: 'recovery_email',
      label: 'Email ID',
      placeholder: 'Enter Email ID',
      validation: [
        Validators.email
      ],
      errorMessage: {required: 'Email ID is required', invalid: 'Invalid Email Id'}
    },
    residentialAddress: {
      key: 'address_line1',
      label: 'Residential Address',
      placeholder: 'Enter your Address',
      validation: [],
      errorMessage: {required: 'Residential Address is required'}
    }
  }
}
