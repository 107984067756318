import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'htmlTrim'
})
export class HtmlTrimPipe implements PipeTransform {

  transform(htmlInput: string): string {
    return this.removeEmptySpace(this.removeEmptySpace(htmlInput, 'front'));
  }

  /**
   * Trimming empty spaces from the content
   * @param htmlContent - html content
   * @returns - returns without empty spaces (ex: <p>&nbsp;</p>)
   */
  removeEmptySpace(htmlContent: string, position = 'end') {
    const regFront = position === 'front' ?
                      new RegExp('^<p[^>]*>(?:&nbsp;)*<\/p>') :
                      new RegExp('<p[^>]*>(?:&nbsp;)*<\/p>$');
    if (regFront.test(htmlContent)) {
      htmlContent = this.removeEmptySpace(htmlContent.replace(regFront, ''), position);
    }
    return htmlContent;
  }

}