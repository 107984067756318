export const CkEditorConstant = {

  maxFileSize: 2, // MB

  maxFileErrorMessage: 'Please upload a file size less than 2 MB',

  ckEditorConfig: {
    placeholder: '',
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        'underline',
        'strikethrough',
        '|',
        'alignment',
        'outdent',
        'indent',
        'fontColor',
        'fontBackgroundColor',
        'fontSize',
        'fontFamily',
        '|',
        'imageUpload',
        'blockQuote',
        'insertTable',
        'mediaEmbed',
        'MathType',
        'ChemType',
        'undo',
        'redo'
      ]
    },
    link : {
      addTargetToExternalLinks: true
    },
    language: 'en',
    image: {
      // Configure the available styles.
      styles: [
        'alignLeft', 'alignCenter', 'alignRight'
      ],

      // Configure the available image resize options.
      resizeOptions: [
        {
          name: 'resizeImage:original',
          label: 'Original',
          value: null
        },
        {
          name: 'resizeImage:50',
          label: '50%',
          value: '50'
        },
        {
          name: 'resizeImage:75',
          label: '75%',
          value: '75'
        }
      ],

      // You need to configure the image toolbar, too, so it shows the new style
      // buttons as well as the resize buttons.
      toolbar: [
        'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
        '|',
        'resizeImage',
        '|',
        'imageTextAlternative'
      ]
    },
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells'
      ]
    },
    licenseKey: ''
  },

  ckEditorConfigMob: {
    placeholder: '',
    toolbar: {
      items: [
        'bold',
        'italic',
        'bulletedList',
        'underline',
        'imageUpload'
      ]
    },
    language: 'en',
    licenseKey: ''
  }
};
