import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { APP_URL } from '../../environments/environment';
import { ApplicationConstant } from '../shared/constant/app.constant';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnChanges {

  @Input() currentURL: string;
  currentYear: number;
  footerConfig: any;
  showCourses = false;
  websiteURL = APP_URL.WEBSITE;
  popularCourses = [
    {
      name: 'Master of International Business Administration',
      path: '/master-of-international-business-administration'
    },
    {
      name: 'Master of Business Administration',
      path: '/master-of-business-administration'
    },
    {
      name: 'Master of Business Administration- General Management',
      path: '/mba-general-management'
    },
    {
      name: 'Project Management Practitioner',
      path: '/project-management-practitioner'
    },
    {
      name: 'Doctorate of Business Administration',
      path: '/doctorate-of-business-administration'
    },
    {
      name: 'SCQF Extended Diploma in Business Analytics',
      path: '/scqf-extended-diploma-business-analytics'
    },
    {
      name: 'SCQF Level 11 Diploma in Supply Chain and Logistics Management',
      path: '/diploma-supply-chain-logistics-management'
    },
    {
      name: 'Supply Chain Management Practitioner',
      path: '/supply-chain-management-practitioner'
    },
    {
      name: 'Certified Manager',
      path: '/certified-manager'
    },
    {
      name: 'Strategic Human Resource Management Practitioner - CMI',
      path: '/strategic-hr-management-practitioner'
    },
    {
      name: 'Executive MBA',
      path: '/executive-mba'
    },
    {
      name: 'Executive MBA in Business Analytics',
      path: '/executive-mba-business-analytics'
    },
  ];
  trendingCourses = [
    {
      name: 'Postgraduate Certificate in Finance for Next Generation Managers',
      path: '/pg-certificate-finance-next-generation-managers'
    },
    {
      name: 'Doctorate of Business Administration',
      path: '/doctorate-of-business-administration'
    },
    {
      name: 'Certified Manager',
      path: '/certified-manager'
    },
    {
      name: 'Executive MBA',
      path: '/executive-mba'
    },
    {
      name: 'Master of Business Administration- General Management (Fast Track)',
      path: '/mba-general-management'
    },
    {
      name: 'Executive MBA in Business Analytics',
      path: '/executive-mba-business-analytics'
    },
    {
      name: 'Postgraduate Certificate in Socio-Economic and Legal Framework',
      path: '/pg-certificate-socio-economic-legal-framework'
    },
    {
      name: 'Postgraduate Certificate in Business Analytics',
      path: '/pg-certificate-business-analytics'
    },
    {
      name: 'Postgraduate Certificate in Finance for Next Generation Managers',
      path: '/pg-certificate-finance-next-generation-managers'
    },
    {
      name: 'Postgraduate Certificate in Business Sustainability',
      path: '/pg-certificate-business-sustainability'
    },
    {
      name: 'Supply Chain Management Practitioner',
      path: '/supply-chain-management-practitioner'
    },
    {
      name: 'Strategic Human Resource Management Practitioner',
      path: '/strategic-hr-management-practitioner'
    }
  ];
  topUniversitiesCourses = [
    {
      name: 'Postgraduate Certificate in International Marketing Management',
      path: '/pg-certificate-international-marketing-management'
    },
    {
      name: 'Postgraduate Certificate In International Human Resource Management',
      path: '/pg-certificate-international-hr-management'
    },
    {
      name: 'Postgraduate Certificate in Strategic Management',
      path: '/pg-certificate-strategic-management'
    },
    {
      name: 'Post Graduate Certificate in Procurement & Contracts Management',
      path: '/pgc-procurement-contracts-management'
    },
    {
      name: 'Postgraduate Certificate in Strategic Supply Chain & Logistics Management',
      path: '/pg-certificate-strategic-supply-chain-logistic-management'
    },
    {
      name: 'Postgraduate Certificate in Business Analytics',
      path: '/pgc-human-resource-leadership'
    },
    {
      name: 'Postgraduate Certificate in Human Resource and Leadership',
      path: '/pg-certificate-international-marketing-management'
    },
    {
      name: 'Postgraduate Certificate in Supply Chain Design & Implementation',
      path: '/pg-certificate-supply-chain-design-implementation'
    },
    {
      name: 'Postgraduate Certificate in Managerial Accounting and Finance',
      path: '/pg-certificate-managerial-accounting-finance'
    },
    {
      name: 'Project Management Practitioner',
      path: '/project-management-practitioner'
    },
    {
      name: 'Strategic Human Resource Management Practitioner',
      path: '/strategic-hr-management-practitioner'
    },
    {
      name: 'Supply Chain Management Practitioner',
      path: '/supply-chain-management-practitioner'
    },
  ];
  degreeProgramCourses = [
    {
      name: 'Master of International Business Administration',
      path: '/master-of-international-business-administration'
    },
    {
      name: 'Master of Business Administration',
      path: '/master-of-business-administration'
    },
    {
      name: 'Master of Business Administration- General Management',
      path: '/mba-general-management'
    },
    {
      name: 'Executive MBA',
      path: '/executive-mba'
    },
    {
      name: 'Executive MBA in Business Analytics',
      path: '/executive-mba-business-analytics'
    },
    {
      name: 'Doctorate of Business Administration',
      path: '/doctorate-of-business-administration'
    },
  ];

  constructor() {
    this.footerConfig = {
      shortCourseHeader: [
        ApplicationConstant.appRouting.SHORT_COURSE_LOGIN_URL,
        ApplicationConstant.appRouting.SHORT_COURSE_REGISTER_URL
      ]
    }
  }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }

  ngOnChanges() {
    if (this.currentURL) {
      this.showCourses = this.footerConfig.shortCourseHeader.some(value => this.currentURL.indexOf(value) > -1);
    }
  }

}
