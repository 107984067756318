import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ApplicationConstant } from '../shared/constant/app.constant';
import { CommonService } from './common.service';
import { Router } from '@angular/router';
import { APP_CONFIGURATION, APP_URL } from '../../environments/environment';
import { NgxPermissionsService } from 'ngx-permissions';
import { EndpointConstant } from '../shared/constant/endpoint.constant';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateService {

  constructor(private baseService: BaseService,
              private commonService: CommonService,
              private ngxPermissionsService: NgxPermissionsService,
              private router: Router) {
  }

  /**
   * Get the token.
   */
  getToken(): string {
    const token = localStorage.getItem(ApplicationConstant.storageKeys.AUTH_TOKEN);
    return token ? `Bearer ${token}` : '';
  }

  /**
   * Set the token.
   * @param token for api calls
   */
  setToken(token: string) {
    localStorage.setItem(ApplicationConstant.storageKeys.AUTH_TOKEN, token);
  }

  /**
   * Check if the token is available.
   */
  isTokenAvailable(): boolean {
    return localStorage.getItem(ApplicationConstant.storageKeys.AUTH_TOKEN) != null;
  }

  /**
   * Check the user is admin.
   */
  checkAdminUserAndRoutePermission(url: string): Promise<boolean> {
    return new Promise(resp => {
      this.baseService.get(`${ApplicationConstant.endpoint.USER_DETAILS_URL}/${this.commonService.getUId()}`)
        .subscribe(res => {
          if (!(res.changed_role_id.indexOf(APP_CONFIGURATION.ADMIN) > -1)) {
            this.router.navigateByUrl('/login');
            resp(false);
          } else {
            resp(this.getPermissions(res.mail));
          }
      }, error => {
        console.log('error', error);
        localStorage.clear();
        this.delete_cookie('hideOnBoarding');
        window.location.href = `${APP_URL.OLD_LMS}${ApplicationConstant.endpoint.OLD_LMS_LOGIN_URL}`;
        resp(false);
      });
    });
  }

  /**
   * Get permissions for the admin user.
   *
   * @param email
   */
  getPermissions(email: string): Promise<boolean> {
    return new Promise(resp => {
      this.baseService.get(EndpointConstant.USER_PERMISSIONS_GET).subscribe(value => {
        const permissions = [];
        value?.permission?.permission_details?.forEach(value => {
          permissions.push(value?.module_permission);
        });
        this.ngxPermissionsService.loadPermissions(permissions);
        resp(true);
      }, error => {
        console.error('Error', error);
        resp(false);
      })
    });
  }

  /**
   * Get the authenticate user.
   *
   * @param user details
   * @param isEmailRequest true/false
   */
  checkSessionAndGetUserToken(user: any, isEmailRequest: boolean) {
    if (isEmailRequest) {
      return this.baseService.post(ApplicationConstant.endpoint.EMAIL_TOKEN_URL, user);
    } else {
      return this.baseService.post(ApplicationConstant.endpoint.OLD_LMS_TOKEN_URL, user);
    }
  }

  /**
   * Validate the user from the old lms.
   *
   * @param userDetails user information.
   * @param url landing page.
   * @return true/false
   */
  validateUserSessionInOldLms(userDetails: any, url: string): Promise<boolean> {
    return new Promise(resp => {
      this.checkSessionAndGetUserToken(userDetails, false).subscribe(res => {
        this.commonService.setUId(res.data.user_details.uid);
        this.setToken(res.data.token);
        this.commonService.setFirstName(res.data.user_details.first_name);
        if (res.data.user_details.cid) {
          this.commonService.course = res.data.user_details.cid;
        }
        this.router.navigateByUrl(url);
        resp(true);
      }, error => {
        console.log('error', error);
        localStorage.clear();
        this.delete_cookie('hideOnBoarding');
        window.location.href = `${APP_URL.OLD_LMS}${ApplicationConstant.endpoint.OLD_LMS_LOGIN_URL}`;
        resp(false);
      });
    });
  }

  /**
   * Validate the user from the email.
   *
   * @param userDetails user information.
   * @param url landing page.
   * @param accessPage temporary page to access.
   * @return true/false
   */
  validateUserFromEmail(userDetails: any, url: string, accessPage: string): Promise<boolean> {
    return new Promise(resp => {
      this.checkSessionAndGetUserToken(userDetails, true).subscribe(res => {
        this.commonService.setUId(res.data.user_details.uid);
        this.setToken(res.data.token);
        this.commonService.setFirstName(res.data.user_details.first_name);
        if (res.data.user_details.cid) {
          this.commonService.course = res.data.user_details.cid;
        }
        this.setTempLogin(accessPage);
        this.router.navigateByUrl(url);
        resp(true);
      }, error => {
        console.log('error', error);
        localStorage.clear();
        resp(false);
        return this.router.navigate([ApplicationConstant.appRouting.LOGIN], { queryParams: { returnUrl: url } });
      });
    });
  }

  /**
   * Delete the cookie from old lms.
   *
   * @param name text
   */
  delete_cookie(name: string) {
    document.cookie = name + '=; domain=.athena.edu;Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  /**
   * Check if the session is Temporary access.
   */
  isTempLogin() {
    const tempLogin: any = localStorage.getItem(ApplicationConstant.storageKeys.TEMP_LOGIN);
    return !!tempLogin;
  }

  /**
   * Set the Temporary Login page.
   *
   * @param page temporary page to access.
   */
  setTempLogin(page: string) {

    const tempLogin = {value: true, accessPage: page};
    localStorage.setItem(ApplicationConstant.storageKeys.TEMP_LOGIN, JSON.stringify(tempLogin));
  }

  /**
   * Get the temporary access page.
   */
  getTempAccessPage() {
    const tempLogin: any = localStorage.getItem(ApplicationConstant.storageKeys.TEMP_LOGIN);
    return tempLogin ? JSON.parse(tempLogin).accessPage : '';
  }
}
