import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { CkEditorService } from './ck-editor.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as ClassicEditor from './ckeditor.js';
import { CkEditorConstant } from '../../constant/ck-editor.constant';

@Component({
  selector: 'app-ck-editor',
  templateUrl: './ck-editor.component.html',
  styleUrls: ['./ck-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CkEditorComponent),
      multi: true
    }
  ]
})
export class CkEditorComponent implements ControlValueAccessor, OnInit {

  @Input() readonlyMode = false;
  @Input() placeholder = '';
  @Input() title = '';
  @Input() showCkEditorViewer = false;
  @Input() showCkEditorAndriodWeb = false;
  ckEditorModel = '';
  config = JSON.parse(JSON.stringify(CkEditorConstant.ckEditorConfig));
  editor = ClassicEditor;

  constructor(private ckEditorService: CkEditorService) { }

  ngOnInit(): void {
    this.config.placeholder = this.placeholder;
    if (this.showCkEditorAndriodWeb) {
      this.config = JSON.parse(JSON.stringify(CkEditorConstant.ckEditorConfigMob));
    }
    if (this.readonlyMode) {
      //remove the toolbar from the ck-editor in read only mode
      this.config.toolbar.items = [];
    }   
  }

  /**
   * Function to call when the ckEditorModel changes.
   * @param _ value
   */
  onChange = (_: any) => { };

  /**
   * Function to call when the input is touched.
   * @param _ value
   */
  onTouch = (_: any) => { };

  /**
   * Read the uploaded content
   * @param eventData event
   */
  onCkEditorReady(eventData) {
    eventData.setData( this.ckEditorModel );
    eventData.ui.getEditableElement().parentElement.insertBefore(eventData.ui.view.toolbar.element, eventData.ui.getEditableElement());

    if (this.readonlyMode) {
      const myFeatureLockId = Symbol( 'my-feature' );
      eventData.enableReadOnlyMode(myFeatureLockId);
    }
    
    this.ckEditorService.customUploadAdapterPlugin(eventData);
  }

  /**
   * Function to call when the model (ckEditorModel) changes.
   * @param fn new value
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Function to call when the input has been touched.
   * @param fn new value
   */
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  /**
   * To update the model (ckEditorModel).
   * @param value updated
   */
  writeValue(value: any): void {
    if (value) {
      this.ckEditorModel = value;
      this.onChange(value);
    } else {
      this.ckEditorModel = '';
      this.onChange(this.ckEditorModel);
    }
  }

  /**
   * Update on the model change.
   *
   * @param data is the updated value.
   */
  onModelChange(data: string) {
    this.ckEditorModel = data;
    this.onChange(this.ckEditorModel);
  }

  /**
   * Show the ck editor modal.
   */
  showCkEditorModal() {

    const dialog = {
      config: this.config,
      editor: this.editor,
      title: this.title,
      ckEditorModel: this.ckEditorModel
    };

    this.ckEditorService.showCkEditorModal(dialog).then(value => {
      if (value) {
        this.writeValue(value);
        this.onChange(value);
      }
    }, error => {
      console.log('error', error);
    });
  }

}
