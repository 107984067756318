<div class="athena-header col-md-12">
  <div class="logo col-2" (click)="redirectToHome()">
    <img src="assets/uniathena-logo.svg" />
  </div>
  <div class="navigation col-10">
    <div *ngIf="!showNavigation" class="mobile-menu-icon float-right d-block d-md-block d-lg-none"
      (click)="toggleMenu(true)">
      <span></span>
    </div>
    <div *ngIf="mobileViewMenu && showNavigation && isDiscussionBoard">
      <div class="nav-links col-md-12">
        <div class="d-block d-md-block d-lg-none">
          <div class="row pb-3 pl-4 pr-4">
            <div class="logo col-3" (click)="redirectToHome()">
              <img src="assets/uniathena-logo.svg" style="margin-left:6px;" />
            </div>
            <div class="col-9 text-right pt-2" (click)="toggleMenu(false)">
              <img src="assets/close.svg" class="link-close"
                style="opacity: 0.4; width: 35px; height: auto;position:fixed;top:20px;right:20px;" />
            </div>
          </div>
        </div>
        <div class="profile-img col-md-12 col-lg-2 dropdown-icon d-block d-md-block d-lg-none mobile">
          <img [src]="getProfilePic(userProfileImage)" class="d-block d-md-block d-lg-none" />
          <span class="d-block d-md-block d-lg-none">{{ firstName }}</span>
        </div>
        <div class="profile-img col-md-12 col-lg-2 dropdown-icon">
          <div class="dropdown-content">
            <p (click)="openDashboard()">Dashboard</p>
            <p (click)="openProjectDetails()">Project Details</p>
            <p (click)="openApplication()">Application</p>
            <p (click)="openOnlineClasses()">Online Classes</p>
            <p (click)="openProfile()">My Profile</p>
            <p (click)="profile()">Profile</p>
            <p (click)="changePassword()">Change Password</p>
            <p (click)="logout()" style="border: none;">Logout</p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!mobileViewMenu && isDiscussionBoard" class="menuOnMobile">
      <div class="container d-block d-md-none">
        <div class="row pt-2 pb-3 p-4">
          <div class="col-4 pl-2 pr-0">
            <img src="assets/uniathena-logo.svg" style="max-width: 100%;" />
          </div>
          <div class="col-8 text-right pt-2" (click)="toggleMenu(false)">
            <img src="assets/close.svg" class="link-close" style="opacity: 0.4; width: 35px; height: auto;" />
          </div>
        </div>
      </div>
      <div *ngIf="showNavigation" class="nav-links col-md-12">
        <div class="profile-img col-md-2 dropdown-icon d-block d-md-none mobile">
          <img [src]="getProfilePic(userProfileImage)" class="d-block d-md-none" />
          <span class="d-block d-md-none">{{ firstName }}</span>
        </div>
        <p class="col-md-2" (click)="openDashboard()">Dashboard</p>
        <p class="col-md-2" (click)="openProjectDetails()">Project Details</p>
        <p class="col-md-2" (click)="openApplication()">Application</p>
        <p class="col-md-2" (click)="openOnlineClasses()">Online Classes</p>
        <p class="col-md-2" (click)="openProfile()">My Profile</p>
        <div class="profile-img col-md-2 dropdown-icon">
          <div class="drop-img">
            <img [src]="getProfilePic(userProfileImage)" class="d-none d-md-block" />
            <span class="d-none d-md-block">{{ firstName }}</span>
            <img class="dropdown d-none d-md-block" src="assets/discussion-forum/dropdown.svg" />
            <div class="dropdown-content">
              <p (click)="goToProfile()">Profile</p>
              <p (click)="changePassword()">Change Password</p>
              <p (click)="logout()" style="border: none;">Logout</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--This Menu shows on other than discussion board for desktop and tablet-->
    <div *ngIf="!mobileViewMenu &&!showNavigation && !isDiscussionBoard" class="menuOnMobile">
      <div class="nav-links col-md-12">
        <ng-container>
          <p class="col-md-2 d-none d-md-block"></p>
          <p class="col-md-2 d-none d-md-block"></p>
          <p class="col-md-2 d-none d-md-block"></p>
          <p class="col-md-2 d-none d-md-block"></p>
          <p class="col-md-2 d-none d-md-block"></p>
        </ng-container>
        <div class="profile-img col-md-2 dropdown-icon">
          <div class="drop-img">
            <img [src]="getProfilePic(userProfileImage)" class="d-none d-md-block" />
            <span class="d-none d-md-block">{{ firstName }}</span>
            <img class="dropdown d-none d-md-block" src="assets/discussion-forum/dropdown.svg" />
            <div class="dropdown-content">
              <p (click)="profile()">Profile</p>
              <p (click)="changePassword()">Change Password</p>
              <p (click)="logout()" style="border: none;">Logout</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--This Menu shows on other than discussion board for mobile-->
    <div *ngIf="mobileViewMenu && showNavigation && !isDiscussionBoard">
      <div class="nav-links col-md-12">

        <div class="d-block d-md-block d-lg-none">
          <div class="row pb-3 pl-4 pr-4">
            <div class="logo col-3" (click)="redirectToHome()">
              <img src="assets/uniathena-logo.svg" style="margin-left:6px;" />
            </div>
            <div class="col-9 text-right pt-2" (click)="toggleMenu(false)">
              <img src="assets/close.svg" class="link-close"
                style="opacity: 0.4; width: 35px; height: auto;position:fixed;top:20px;right:20px;" />
            </div>
          </div>
        </div>
        <div class="profile-img col-md-12 col-lg-2 dropdown-icon d-block d-md-block d-lg-none mobile">
          <img [src]="getProfilePic(userProfileImage)" class="d-block d-md-block d-lg-none" />
          <span class="d-block d-md-block d-lg-none">{{ firstName }}</span>
        </div>
        <div class="profile-img col-md-12 col-lg-2 dropdown-icon">
          <div class="dropdown-content">
            <p (click)="profile()">Profile</p>
            <p (click)="changePassword()">Change Password</p>
            <p (click)="logout()" style="border: none;">Logout</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>