<div class="container-fluid form-header">
  <div class="header-container">
    <h1 class="header-text">Register</h1>
  </div>
</div>
<div class="container login">
  <div class="col-md-12 row user-details">
    <div class="col-md-5 logo">
      <img src="assets/uniathena-logo.svg" />
    </div>
    <div class="col-md-7 user-info">
      <div class="username">
        <label>First Name</label>
        <input
          [ngClass]="{ invalid: firstNameError }"
          [(ngModel)]="firstName"
          placeholder="First Name"
          (keyup.enter)="registerUser()"
          type="name"
          required
        />
      </div>
      <div class="username">
        <label>Email</label>
        <input
          [ngClass]="{ invalid: emailError || emailIdError }"
          placeholder="Email"
          [(ngModel)]="emailId"
          (keyup.enter)="registerUser()"
          type="email"
          required
        />
      </div>
      <div class="password">
        <label>Password</label>
        <input
          [ngClass]="{ invalid: passwordsDontMatch || userPasswordError }"
          placeholder="Password"
          [(ngModel)]="password"
          (keyup.enter)="registerUser()"
          [type]="showPass ? 'text' : 'password'"
          required
        /><span class="eye"
          ><img src="assets/eye.svg" (click)="showPassword('pass')"
        /></span>
      </div>
      <div class="password">
        <label>Confirm Password</label>
        <input
          [ngClass]="{ invalid: passwordsDontMatch || confirmPasswordError }"
          placeholder="Password"
          [(ngModel)]="confirmPassword"
          (keyup.enter)="registerUser()"
          [type]="showConfirmPass ? 'text' : 'password'"
          required
        /><span class="eye"
          ><img src="assets/eye.svg" (click)="showPassword('confirmPass')"
        /></span>
      </div>
      <p
        class="error"
        *ngIf="
          firstNameError ||
          emailIdError ||
          userPasswordError ||
          confirmPasswordError
        "
      >
        Please enter all the fields
      </p>
      <p class="error" *ngIf="emailError">Please enter a valid Email</p>
      <p class="error" *ngIf="passwordsDontMatch">Passwords did not match!</p>
      <p class="error" *ngIf="apiError">
        Something went wrong!! Please try later.
      </p>
      <p class="error" *ngIf="emailRegistered">
        Looks like you are already registered with us. <br />Please click
        <a [routerLink]="['/login']">here</a> to login
      </p>
      <p class="success" *ngIf="showSuccessMessage">
        Successfully registered. <br />Please proceed to login by clicking
        <a [routerLink]="['/login']">here</a>.
      </p>
      <div class="col-md-12 login-new row">
        <div class="col-md-8 forgot-password">
          <!-- <p>Forgot Password? <span>Click here</span></p> -->
        </div>
        <div class="col-md-4 submit">
          <button (click)="registerUser()">
            Register
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
