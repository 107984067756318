import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './shared/component/page-not-found/page-not-found.component';
import { RegisterComponent } from './register/register.component';
import { SharedModule } from './shared/modules/shared/shared.module';
import { AcademicHeaderComponent } from './header/academic-header/academic-header.component';
import { PlainHeaderComponent } from './header/plain-header/plain-header.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { RegisterFormComponent } from './register/register-form/register-form.component';
import { ForgotPasswordDialogComponent } from './forgot-password/dialog/forgot-password-dialog/forgot-password-dialog.component';
import { ResetPasswordComponent } from './forgot-password/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { CkEditorDiscussionComponent } from './ck-editor-discussion/ck-editor-discussion.component';
import { CkEditorSuccessComponent } from './ck-editor-success/ck-editor-success.component';

@NgModule({
  declarations: [
    AcademicHeaderComponent,
    AppComponent,
    FooterComponent,
    ForgotPasswordComponent,
    ForgotPasswordDialogComponent,
    HeaderComponent,
    LoginComponent,
    PageNotFoundComponent,
    PlainHeaderComponent,
    RegisterComponent,
    RegisterFormComponent,
    ResetPasswordComponent,
    CkEditorDiscussionComponent,
    CkEditorSuccessComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    SharedModule,
    NgxPermissionsModule.forRoot()
  ],
  bootstrap: [
    AppComponent
  ],
  entryComponents: [
    ForgotPasswordDialogComponent,
    RegisterFormComponent
  ]
})
export class AppModule { }
