import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';
import {NotifierData} from './notifier-data.interface';

@Component({
  selector: 'app-application-notifier',
  templateUrl: './application-notifier.component.html',
  styleUrls: ['./application-notifier.component.scss']
})
export class ApplicationNotifierComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: NotifierData) { }

  ngOnInit(): void {
  }

}
