import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { APP_URL } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor(private httpClient: HttpClient) { }

  /**
   * This is a get method for api call.
   * @param endpoint which will be used for getting the data.
   * @param params additional information
   */
  get(endpoint: string, params?: HttpParams): Observable<any> {
    return this.httpClient.get(`${APP_URL.API}${endpoint}`)
      .pipe(catchError((error: any) => { return throwError(error); }));
  }

  /**
   * This is the post method for api call.
   * @param endpoint which will be used for posting the data.
   * @param data to post
   */
  post(endpoint: string, data: any): Observable<any> {
    return this.httpClient.post(`${APP_URL.API}${endpoint}`, data)
      .pipe(catchError((error: any) => { return throwError(error); }));
  }

  /**
   * This is the put method for api call.
   * @param endpoint which will be used for posting the data.
   * @param data to post
   */
  put(endpoint: string, data: any): Observable<any> {
    return this.httpClient.put(`${APP_URL.API}${endpoint}`, data)
      .pipe(catchError((error: any) => { return throwError(error); }));
  }

  /**
   * This is a delete method for api call.
   * @param endpoint which will be used for deleting the data.
   * @param params additional information
   */
  delete(endpoint: string, params?: HttpParams): Observable<any> {
    return this.httpClient.delete(`${APP_URL.API}${endpoint}`)
      .pipe(catchError((error: any) => { return throwError(error); }));
  }

  /**
   * This is the get method for api call for third party
   * @param endpoint 
   * @returns 
   */
  getThirdParty(endpoint: string): Observable<any> {
    return this.httpClient.get(`${endpoint}`)
      .pipe(catchError((error: any) => { return throwError(error); }));
  }

  /**
   * This is the post method for api call for third party
   * @param endpoint 
   * @param data 
   * @param token 
   * @returns 
   */
  postThirdParty(endpoint: string, data: any, token: any= ''): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    if (token !== '') {
      headers  = headers.append('Authorization',  `Bearer ${token}`);
    }
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('accept', 'application/json');

    return this.httpClient.post(`${endpoint}`, data, {headers})
      .pipe(catchError((error: any) => {
        return throwError(error);
    }));
  }

  /**
   * get details from third party and set headers
   * @param url 
   * @param token 
   * @returns 
   */
  getDetailsFromThirdParty(url: string, token: string) {
    if (token) {
      return fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer "' + token + '"'
        }
      });
    } else {
      return fetch(url, {
        method: 'GET',
      });
    }
  }

  /**
   * get details from Acads Team and set headers
   * @param endpoint 
   * @returns 
   */
  getAcadsTeam(endpoint: string): Observable<any> {
    const headers = new HttpHeaders({'SkipAuthorization': 'true'});
    const options = { headers: headers };
  
    return this.httpClient.get(`${APP_URL.ACADS_API}${endpoint}`, options)
      .pipe(catchError((error: any) => {
        return throwError(error);
      }));
  }

  /**
   * post details to Acads Team and set headers
   * @param data 
   * @param endpoint 
   * @returns 
   */
  postAcadsTeam(endpoint: string, data:any): Observable<any> {
    
    let headers = new HttpHeaders({'SkipAuthorization': 'true'});
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('accept', 'application/json');
    const options = { headers: headers };
  
    return this.httpClient.post(`${APP_URL.ACADS_API}${endpoint}`, data, options)
      .pipe(catchError((error: any) => {
        return throwError(error);
      }));
  }

  /**
   * Get encryted details from website endpoint
   * @param endpoint 
   * @returns 
   */
  getEncryptData(endpoint: string): Observable<any> {
    const requestOptions: Object = {
      responseType: 'text'
    };
  
    return this.httpClient.get(`${APP_URL.WEBSITE_ENCRYPT_API}${endpoint}`, requestOptions)
      .pipe(catchError((error: any) => {
        return throwError(error);
      }));
  }

  /**
   * This is a get method for api call.
   * @param endpoint which will be used for getting the data.
   * @param params additional information
   */
  getMethod(url: string, endpoint: string, params?: HttpParams): Observable<any> {
    return this.httpClient.get(`${url}${endpoint}`)
      .pipe(catchError((error: any) => { return throwError(error); }));
  }
  
  /**
   * This is the post method for api call.
   * @param endpoint which will be used for posting the data.
   * @param data to post
   * @param url - api base url
  */
  postMethod(url: string, endpoint: string, data: any): Observable<any> {
    return this.httpClient.post(`${url}${endpoint}`, data)
      .pipe(catchError((error: any) => throwError(error)));
  }

  /**
   * This is the put method for api call.
   * @param endpoint which will be used for modifying the data.
   * @param data to put
   * @param url - api base url
  */
  putMethod(url: string, endpoint: string, data: any): Observable<any> {
    return this.httpClient.put(`${url}${endpoint}`, data)
      .pipe(catchError((error: any) => throwError(error)));
  }

  /**
   * This is the delete method for api call.
   * @param endpoint which will be used for deleting the data.
   * @param data to delete
   * @param url - api base url
  */
  deleteMethod(url: string, endpoint: string): Observable<any> {
    return this.httpClient.delete(`${url}${endpoint}`)
      .pipe(catchError((error: any) =>throwError(error)));
  }
}
