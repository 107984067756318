import {Validators} from '@angular/forms';
import {ApplicationConstant} from '../shared/constant/app.constant';
import {ReasonToJoinOrExpectationValidator } from '../shared/validator/description.validator';

/**
 * Constant file for the Quick Application
 */
export const QuickApplicationConstant = {

  steps: [
    { title: 'Documents',
      path: ApplicationConstant.appRouting.QUICK_APPLICATION_DOCUMENT,
      position: 0
    },
    { title: 'Educational Details',
      path: ApplicationConstant.appRouting.QUICK_APPLICATION_EDUCATION,
      position: 1
    },
    { title: 'Work Details',
      path: ApplicationConstant.appRouting.QUICK_APPLICATION_WORK_EXP,
      position: 2
    },
    { title: 'Contact Details',
      path: ApplicationConstant.appRouting.QUICK_APPLICATION_CONTACT,
      position: 3
    }],

  workExperience: [{name: 'Yes', value: 'y'}, {name: 'No', value: 'n'}],

  userDetailsForm: {
    course_id: {
      key: 'cid',
      label: 'Select the course',
      placeholder: 'Select course',
      validation: [
        Validators.required
      ],
      errorMessage: {required: 'Course is required'}
    },
    first_name: {
      key: 'first_name',
      label: 'First Name',
      placeholder: 'First Name',
      validation: [
        Validators.required,
        Validators.pattern('^[a-zA-Z \".-]+$'),
        Validators.maxLength(100),
        Validators.minLength(3)
      ],
      errorMessage: {required: 'First name is required', invalid: 'First name is invalid'}
    },
    last_name: {
      key: 'last_name',
      label: 'Last Name',
      placeholder: 'Last Name',
      validation: [
        Validators.required,
        Validators.pattern('^[a-zA-Z \".-]+$'),
        Validators.maxLength(100)
      ],
      errorMessage: {required: 'Last name is required', invalid: 'Last name is invalid'}
    },
    dob: {
      key: 'dob',
      label: 'Date of Birth',
      placeholder: 'MM/DD/YYYY',
      customValidation: {maxAge: 18},
      validation: [
        Validators.required
      ],
      errorMessage: {required: 'DOB is required', invalid: 'Invalid DOB'}
    },
    country_id: {
      key: 'country_id',
      name: 'country_name',
      label: 'Nationality',
      placeholder: 'Select nationality',
      validation: [
        Validators.required
      ],
      errorMessage: {required: 'Nationality is required'}
    },
    residence_country_id: {
      key: 'residence_id',
      name: 'residence_name',
      label: 'Country of residence',
      placeholder: 'Select country',
      validation: [
        Validators.required
      ],
      errorMessage: {required: 'Country of residence is required'}
    },
    contact_number: {
      key: 'contact_number',
      label: 'Contact Number',
      country_code: 'country_contact_code',
      country_iso: 'phone_iso',
      placeholder: '',
      validation: [
        Validators.required
      ],
      errorMessage: {
        required: 'Contact number is required',
        invalid: 'Invalid contact number',
        isExist: 'Contact number already been taken'
      }
    },
    gender: {
      key: 'gender',
      label: 'Gender',
      placeholder: 'Select gender',
      validation: [
        Validators.required
      ],
      errorMessage: {required: 'Gender is required'}
    },
    highest_qualification: {
      key: 'high_qal_id',
      label: 'What is your highest qualification?',
      name: 'qualification_name',
      placeholder: 'Select highest qualification',
      validation: [
        Validators.required
      ],
      errorMessage: {required: 'Highest qualification is required'}
    },
    qual_name: {
      key: 'qual_name',
      label: 'Name of your Qualification',
      placeholder: 'Enter your Qualification',
      validation: [
        Validators.required,
        Validators.maxLength(100)
      ],
      errorMessage: {required: 'Qualification name is required'}
    },
    univ_awar_body: {
      key: 'univ_award_body',
      label: 'University/ Awarding body',
      placeholder: 'University/ Awarding body',
      validation: [
        Validators.required,
        Validators.maxLength(100)
      ],
      errorMessage: {required: 'University/ Awarding body is required'}
    },
    work_exp: {
      key: 'work_experience',
      label: 'Do you have work experience ?',
      placeholder: '',
      validation: [
        Validators.required
      ],
      type: 'experience',
      errorMessage: {required: 'Work experience is required'}
    },
    payment_type: {
      key: 'payment_type',
      label: 'Which payment type would you like to proceed with ?',
      placeholder: '',
      validation: [
        Validators.required
      ],
      errorMessage: {required: 'Payment type is required'}
    },
    total_year_exp: {
      key: 'years_exp',
      label: 'Total Years / Months of Experience',
      placeholder: 'Year',
      validation: [
        Validators.required
      ],
      type: 'experience',
      errorMessage: {required: 'Year of experience is required'}
    },
    total_month_exp: {
      key: 'months_exp',
      label: '',
      placeholder: 'Month',
      validation: [
        Validators.required
      ],
      type: 'experience',
      errorMessage: {required: 'Month of experience is required'}
    },
    level_employment_id: {
      key: 'grade_id',
      label: 'What\'s your level of employment?',
      name: 'grade',
      placeholder: 'Select current employment level',
      validation: [
        Validators.required
      ],
      type: 'experience',
      errorMessage: {required: 'Level of employment is required'}
    },
    cur_designation: {
      key: 'current_designation',
      label: 'Your Current Designation',
      placeholder: 'Current Designation',
      validation: [
        Validators.required
      ],
      type: 'experience',
      errorMessage: {required: 'Current Designation is required'}
    },
    industry_work_id: {
      key: 'industry_id',
      label: 'Industry you currently work with',
      name: 'industry',
      placeholder: 'Select your industry',
      validation: [
        Validators.required
      ],
      type: 'experience',
      errorMessage: {required: 'Currently working industry is required'}
    },
    fund_course: {
      key: 'fund_id',
      label: 'How are you going to fund the course?',
      placeholder: 'Select',
      validation: [
        Validators.required
      ],
      type: '',
      errorMessage: {required: 'Course fund is required'}
    },
    enrollment_period_id: {
      key: 'enroll_time_id',
      label: 'How soon will you enroll?',
      placeholder: 'Select',
      validation: [
        Validators.required
      ],
      type: '',
      errorMessage: {required: 'Course enroll is required'}
    },
    qf_term_consent: {
      key: 'consent_confirmation',
      label: 'I hereby confirm that the above information provided is accurate, and I undertake to submit the proof required upon demand ' +
        'from Athena Global Education. I also understand the decision of admission to the programme I have applied will be solely based ' +
        'on the information I have provided and it will be invalid if anything found not factual.',
      validation: [
        Validators.requiredTrue
      ],
      errorMessage: {required: 'Consent is required'}
    },
    qf_agree_consent: {
      key: 'consent_terms',
      label: 'I accept the Terms and conditions of Athena Global Education.',
      validation: [
        Validators.requiredTrue,
      ],
      errorMessage: {required: 'Terms and condition is required'}
    },
    reasonToJoin: {
      key: 'reason_to_join',
      label: 'Why do you want to join this program? (50 words)',
      placeholder: 'Type here',
      validation: [
        Validators.required,
        ReasonToJoinOrExpectationValidator.reasonToJoinOrExpectationMaxContent
      ],
      errorMessage: {required: 'Reason is required', invalid: 'Word Limit (500 words) Exceeded'}
    },
    expectation: {
      key: 'expectation',
      label: 'Your expectation in terms of learning and skill development by the end of the program? (50 words)',
      placeholder: 'Type here',
      validation: [
        Validators.required,
        ReasonToJoinOrExpectationValidator.reasonToJoinOrExpectationMaxContent
      ],
      errorMessage: {required: 'Expectation is required', invalid: 'Word Limit (500 words) Exceeded'}
    }
  },

  documentDetailsForm: {
    proofType: {
      key: 'proof_type_id'
    },
    photo_upload: {
      key: 'user_photo',
      label: '',
      placeholder: '',
      validation: [],
      errorMessage: {required: 'Grade is required'}
    },
    passport_upload: {
      key: 'user_passport',
      label: '',
      placeholder: '',
      validation: [],
      errorMessage: {required: 'Grade is required'}
    }
  },

  educationDetailsForm: {

    qualificationLevel: {
      key: 'high_qal_id',
      label: 'Select your Level of your Qualification',
      placeholder: 'Select the Level',
      validation: [],
      errorMessage: {required: 'Qualification level is required'}
    },
    qualificationName: {
      key: 'qual_name',
      label: 'Name of the Qualification',
      placeholder: 'BSc Maths, BA History, Higher-Diploma in Business',
      validation: [],
      errorMessage: {required: 'Qualification name is required'}
    },
    universityName: {
      key: 'univ_award_body',
      label: 'Name of the University',
      placeholder: 'University Name',
      validation: [],
      errorMessage: {required: 'University name is required'}
    },
    country: {
      key: 'country_id',
      name: 'country_name',
      label: 'Country',
      placeholder: 'Select country',
      validation: [],
      errorMessage: {required: 'Country is required'}
    },
    grades: {
      key: 'grade_marks',
      label: 'Grade/Marks',
      placeholder: 'Overall Grade, Total Marks, CGPA,',
      validation: [],
      errorMessage: {required: 'Grade/Marks is required'}
    },
    passout_year: {
      key: 'year_of_passing',
      label: 'Year of Passing',
      placeholder: 'Passout Year',
      validation: [],
      errorMessage: {required: 'Passout year is required'}
    },
    certificate_upload_name: {
      key: 'certificate_upload_text',
      label: 'Copy of  Certificate',
      placeholder: ' Name of Certificate',
      validation: [],
      errorMessage: {required: 'Document Name is required'}
    },
    certificate_upload: {
      key: 'certificate_upload',
      label: '',
      placeholder: '',
      validation: [],
      errorMessage: {required: 'Certificate is required'}
    },
    grade_upload_name: {
      key: 'grade_upload_text',
      label: 'Copy of Grade Sheet / Transcript / Mark list',
      placeholder: 'Grade sheet / Transcript / Marklist Name',
      validation: [],
      errorMessage: {required: 'Document Name is required'}
    },
    grade_upload: {
      key: 'grade_upload',
      label: '',
      placeholder: '',
      validation: [],
      errorMessage: {required: 'Grade is required'}
    }
  },

  workDetailsForm: {

    company_name: {
      key: 'company_name',
      label: 'Company Name',
      placeholder: 'Name of the company',
      validation: [],
      errorMessage: {required: 'Company Name is required'}
    },
    industry: {
      key: 'industry_id',
      label: 'Industry',
      placeholder: 'Select your industry',
      validation: [],
      errorMessage: {required: 'Industry is required'}
    },
    cur_working: {
      key: 'current_company',
      label: 'Are you currently working here?',
      placeholder: '',
      validation: [],
      errorMessage: {required: 'Currently working is required'}
    },
    start_date_year: {
      key: 'work_start_year',
      label: 'Start Date',
      placeholder: '1970',
      validation: [],
      errorMessage: {required: 'Start year is required', invalid: 'Experience is invalid'}
    },
    start_date_month: {
      key: 'work_start_month',
      label: '',
      placeholder: 'January',
      validation: [],
      errorMessage: {required: 'Start month is required'}
    },
    end_date_year: {
      key: 'work_end_year',
      label: 'End Date',
      placeholder: '1980',
      validation: [],
      errorMessage: {required: 'End year is required'}
    },
    end_date_month: {
      key: 'work_end_month',
      label: '',
      placeholder: 'January',
      validation: [],
      errorMessage: {required: 'End month is required'}
    },
    designation: {
      key: 'current_designation',
      label: 'Your Designation',
      placeholder: 'Designation',
      validation: [],
      errorMessage: {required: 'Designation is required'}
    },
    employment_level: {
      key: 'grade_id',
      label: 'What\'s your level of employment?',
      placeholder: 'Select current employment level',
      validation: [],
      errorMessage: {required: 'Employment level is required'}
    },
    resume_upload: {
      key: 'user_resume',
      label: 'Upload your Resume',
      placeholder: '',
      validation: [],
      errorMessage: {required: 'Resume is required'}
    },
    work_document_upload_name: {
      key: 'work_upload_text',
      label: 'Upload any Other Documents (experience,Reference etc)',
      placeholder: 'Other Documents name',
      validation: [],
      errorMessage: {required: 'Other Document name is required'}
    },
    work_document_upload: {
      key: 'work_upload',
      label: '',
      placeholder: '',
      validation: [],
      errorMessage: {required: 'Other Document is required'}
    }
  },

  contactDetailsForm: {

    residentialAddress: {
      key: 'address_line1',
      label: 'Residential Address',
      placeholder: 'Enter your Address',
      validation: [],
      errorMessage: {required: 'Residential Address is required'}
    },
    city: {
      key: 'city',
      label: 'City',
      placeholder: 'Enter your Address',
      validation: [],
      errorMessage: {required: 'City is required'}
    },
    postCode: {
      key: 'postcode',
      label: 'Post Code',
      placeholder: 'Enter your Post code',
      validation: [],
      errorMessage: {required: 'Post Code is required'}
    },
    mobile_no: {
      key: 'contact_number',
      label: 'Mobile No',
      country_code: 'country_contact_code',
      country_iso: 'phone_iso',
      placeholder: 'Enter mobile number',
      validation: [Validators.required],
      errorMessage: {
        required: 'Mobile No is required',
        invalid: 'Invalid Mobile No',
        isExist: 'Mobile No already been taken'
      }
    },
    country: {
      key: 'country_id',
      name: 'country_name',
      label: 'Country',
      placeholder: 'Select your Country',
      validation: [Validators.required],
      errorMessage: {required: 'Country is required'}
    },
    office_tel_number: {
      key: 'office_tel_number',
      label: 'Telephone (office/Residence)',
      country_code: 'office_contact_code',
      country_iso: 'office_iso',
      placeholder: 'Enter Telephone number',
      validation: [],
      errorMessage: {required: 'Telephone No is required', invalid: 'Invalid Telephone No'}
    },
    email_Id: {
      key: 'mail',
      label: 'Email ID',
      placeholder: 'Enter Email ID',
      validation: [
        Validators.email
      ],
      errorMessage: {required: 'Email ID is required', invalid: 'Invalid Email Id'}
    },
    alternate_email_Id: {
      key: 'alternate_mail',
      label: 'Alternative Email ID',
      placeholder: 'Enter Email ID',
      validation: [
        Validators.email
      ],
      errorMessage: {required: 'Alternative Email ID is required', invalid: 'Invalid Alternative Email Id'}
    },
    emergency_contact_name: {
      key: 'emergency_contact_name',
      label: 'Emergency Contact Name',
      placeholder: 'Contact name',
      validation: [],
      errorMessage: {required: 'Emergency Contact Name is required'}
    },
    emergency_contact_num: {
      key: 'emergency_contact_number',
      label: 'Emergency Contact No',
      country_code: 'emergency_contact_code',
      country_iso: 'emergency_iso',
      placeholder: 'Enter Mobile number',
      validation: [],
      errorMessage: {required: 'Emergency Contact No is required', invalid: 'Invalid Emergency Contact No'}
    }
  },
  educationDetails: {
    qualificationName: {
      label: 'Qualification name'
    },
    universityName: {
      label: 'University Name'
    },
    documents: {
      label: 'Documents'
    },
    highestQualification: {
      label: 'Highest qualification'
    }
  },

  workExperienceDetails: {
    company_name: {
      label: 'Company name'
    },
    designation: {
      label: 'Designation'
    },
    documents: {
      label: 'Documents'
    },
    experience: {
      label: 'Total Experience'
    },
    industry: {
      label: 'Industry'
    },
    grade: {
      label: 'Grade'
    }
  },
  PASSPORT_TYPE_ID: 1,
  OTHERS_TYPE_ID: 7
};
