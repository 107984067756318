import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticateService } from '../services/authenticate.service';
import { HtmlTrimPipe } from '../shared/pipe/html-trim.pipe';
import { DiscussionForumService } from '../dashboard/discussion-forum-service';
import { ApplicationNotifierConstant } from '../shared/constant/notifier.constant';
import { ApplicationNotifierService } from '../shared/component/application-notifier/application-notifier.service';
import { RoutingConstant } from '../shared/constant/routing.constant';

@Component({
  selector: 'app-ck-editor-discussion',
  templateUrl: './ck-editor-discussion.component.html',
  styleUrls: ['./ck-editor-discussion.component.scss'],
  providers: [ HtmlTrimPipe ]
})
export class CkEditorDiscussionComponent implements OnInit {

  commentInput = '';
  showErrorMessage = false;
  enableFaculty = false;
  userId: any;
  keyToken: string;
  commentType = 'post';
  isSubmitted = false;
  postData = {
    author_id: null,
    discussion_id: null
  } as any;
  replyData = {
    author_id: null,
    discussion_id: null,
    post_id: null,
    parent_reply_id: null
  } as any;

  constructor(private authenticateService: AuthenticateService,
              private htmlTrimPipe: HtmlTrimPipe,
              private discussionForumService: DiscussionForumService,
              private activatedRoute: ActivatedRoute,
              private applicationNotifierService: ApplicationNotifierService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.getQueryParams();
  }

  /**
   * Get query params data from the url
   */
  private getQueryParams() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params && params?.type === 'post') {
        this.commentType = 'post';
        this.postData = {...params};
      } else if (params && params?.type === 'reply') {
        this.commentType = 'reply';
        this.replyData = {...params};
      }
      if (params?.token) {
        this.authenticateService.setToken(params.token);
      }
    });
  }

  /**
   * 
   * @param param
   * @param data 
   * @param index 
   * @returns 
   */
  addComment() {
    this.isSubmitted = true;
    let textarea = this.htmlTrimPipe.transform(this.commentInput);
    textarea = this.removeStylesResizeImage(textarea);
    const badWords = /boob|boobs|sex|fcuk|fuck|bitch|dick|suck|stupid/gi;
    const filteredUserReview = textarea.replace(badWords, '******');
    if (!filteredUserReview) {
      this.showErrorMessage = true;
      return;
    } else {
      this.showErrorMessage = false;
    }
    if (this.commentType === 'post') {
      this.createPost(filteredUserReview);
    } else if (this.commentType === 'reply') {
      this.createReply(filteredUserReview);
    }
  }

  /**
   * Create post for the discussion.
   *
   * @param review - filtered comment
   */
  private createPost(review: string) {
    let changedData: any;
    if (review.indexOf('class="media"') > -1) {
      let url: string;
      if (review.indexOf('youtu.be') > -1) {
        url = review.substring(review.indexOf('be/') + 3, review.length)
          .split('">')[0];
        url = `https://www.youtube.com/embed/${url}?rel=0`;
      } else if (review.indexOf('watch?v') === -1) {
        url = review.substring(review.indexOf('be/') + 3, review.length);
        url = `https://www.youtube.com/embed/${url}?rel=0`;
      } else {
        url = review.substring(review.indexOf('url=')).split('>')[0]
          .replace('watch?v=', 'embed/')
          .replace('url=', '');
      }
      changedData = `<iframe src=${url}  allowfullscreen></iframe>`;
      review = `${changedData}${review}`;
    }
    if (review.indexOf('class="image') > -1) {
      review = review.replace(/<img/g, '<img style="width: 100%"');
    }
    const emailUpdate = this.enableFaculty ? 1 : 0;
    const payload = {
      discussion_id: this.postData.discussion_id,
      author_id: this.postData.author_id,
      content: review,
      enable_faculty_status: emailUpdate,
      image: ''
    };
    if (!this.postData.discussion_id || !this.postData.author_id) {
      return;
    }
    this.discussionForumService.createNewPost(payload).then((res: any = {}) => {
        this.commentInput = '';
        this.enableFaculty = false;
        this.isSubmitted = false;
        if (res && res?.data?.moderation_status === 0) {
          this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.comment_admin);
        } else {
          this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.post_comment);
        }
        setTimeout(() => {
          this.redirectToSuccess();
        }, 2000);
      }, err => {
        console.error('Error Registering: ', err);
      });
  }

  /**
   * Create reply for the post.
   *
   * @param review - filtered comment 
   */
  private createReply(review: string) {
    let changedData: any;
    if (review.indexOf('class="media"') > -1) {
      let url: string;
      if (review.indexOf('youtu.be') > -1) {
        url = review.substring(review.indexOf('be/') + 3, review.length).split('">')[0];
        url = `https://www.youtube.com/embed/${url}?rel=0`;
      } else if (review.indexOf('watch?v') === -1) {
        url = review.substring(review.indexOf('be/') + 3, review.length);
        url = `https://www.youtube.com/embed/${url}?rel=0`;
      } else {
        url = review.substring(review.indexOf('url=')).split('>')[0]
          .replace('watch?v=', 'embed/').replace('url=', '');
      }
      changedData = `<iframe src=${url}  allowfullscreen></iframe>`;
      review = `${changedData}${review}`;
    }
    if (review.indexOf('class="image') > -1) {
      review = review.replace(/<img/g, '<img style="width: 100%"');
    }
    const payload = {
      post_id: this.replyData.post_id,
      author_id: this.replyData.author_id,
      content: review,
      parent_reply_id: this.replyData.parent_reply_id,
      discussion_id: this.replyData.discussion_id,
      image: ''
    };
    if (!this.replyData.discussion_id || !this.replyData.author_id || !this.replyData.parent_reply_id || !this.replyData.post_id) {
      return;
    }
    this.discussionForumService.createNewReply(payload).then((res: any = {}) => {
        this.commentInput = '';
        this.isSubmitted = false;
        if (res && res?.data?.moderation_status === 0) {
          this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.comment_admin);
        } else {
          this.applicationNotifierService.getNotifier(ApplicationNotifierConstant.reply_comment);
        }
        setTimeout(() => {
          this.redirectToSuccess();
        }, 2000);
      }, err => {
        console.error('Error Registering: ', err);
      });
  }

  /**
   * Removing inline styles for resized images in the comments 
   */
  private removeStylesResizeImage(content: string) {
    const figureRegex = new RegExp('(<figure)[^\>]*(\>|>)', 'g');
    const styleRegex = new RegExp(' style=("|\')(.*?)("|\')');
    if (!figureRegex.test(content)) {
      return content;
    }
    const figureArr = content.match(figureRegex);
    figureArr.forEach(element => {
      if (element.includes('figure') && element.includes('style')) {
        if(styleRegex.test(element)) {
          const matchedFigure = element.match(styleRegex)[0];
          content = content.replace(matchedFigure, '');
        }
      }
    });
    return content;
  }

  /**
   * Success page for andriod purpose to redirect to app
   */
  private redirectToSuccess() {
    this.router.navigateByUrl(RoutingConstant.CK_EDITOR_SUCCESS);
  }

  /**
   * validate comment
   */
  validateComment() {
    const textarea = this.htmlTrimPipe.transform(this.commentInput);
    this.showErrorMessage = textarea === '' && this.isSubmitted;
  }

}
