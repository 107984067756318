import { Injectable } from '@angular/core';
import { UploadAdapterService } from '../../../services/upload-adapter.service';
import { FormService } from '../../../services/form.service';
import { MatDialog } from '@angular/material/dialog';
import { CkEditorModalComponent } from './ck-editor-modal/ck-editor-modal.component';

@Injectable({
  providedIn: 'root'
})
export class CkEditorService {

  constructor(private formService: FormService,
              private matDialog: MatDialog) { }

  /**
   * My custom adapter to upload the image in ck-editor.
   * @param eventData event
   */
  customUploadAdapterPlugin(eventData) {
    eventData.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new UploadAdapterService(loader, this.formService);
    };
  }

  /**
   * Show the ck-editor modal.
   *
   * @param dialogContent dialog Content for the modal.
   */
  showCkEditorModal(dialogContent: any): Promise<any> {
    const dialogRef = this.matDialog.open(CkEditorModalComponent, {
      height: 'auto',
      width: '900px',
      data: dialogContent
    });
    return dialogRef.afterClosed().toPromise();
  }
}
