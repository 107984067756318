import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthenticateService } from '../services/authenticate.service';
import { Router } from '@angular/router';
import { APP_URL } from '../../environments/environment';
import { LoaderService } from '../services/loader.service';
import { ExcludingLoaderForEndpoints } from '../shared/constant/endpoint.constant';

@Injectable()
export class NetworkInterceptor implements HttpInterceptor {

  private httpRequestsCount = 0;
  
  constructor(private authenticateService: AuthenticateService,
              private loaderService: LoaderService,
              private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!request.url.includes('login') || !request.url.includes('register')) {
      // Adding authentication header to request.
      request = request.clone({
        setHeaders: {
          Authorization: this.authenticateService.getToken()
        }
      });
    }

    /**
     * Triggering loader spinner
     */
    if (this.matchedEndpointIndex(request) == -1) {
      this.httpRequestsCount++;
      this.loaderService.showLoader();
    }

    // Sending user to login page if response's status is 401.
    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // response for the request.
      }
    }, (err: any) => {
      this.httpRequestsCount--;
      if (this.httpRequestsCount <= 0) {
        this.loaderService.hideLoader();
      }
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          localStorage.clear();
          this.delete_cookie('hideOnBoarding');
          // this.router.navigateByUrl(ApplicationConstant.appRouting.LOGIN);
          //  window.location.href = 'https://ulearn.athena.edu/Login';
        }
      }
    }, () => {
      if (this.matchedEndpointIndex(request) == -1) {
        this.httpRequestsCount--;
        if (this.httpRequestsCount <= 0) {
          this.loaderService.hideLoader();
        }
      }
    }));
  }

  delete_cookie(name: string) {
    document.cookie = name + '=; domain=.athena.edu;Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  /**
   * Find the index of the matched url to exclude for spinner
   * @param request - http request
   * @returns - index of the matched url
   */
  private matchedEndpointIndex(request) {
    return ExcludingLoaderForEndpoints.findIndex(url => request.url.match(url));
  }

}
