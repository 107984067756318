import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth-service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  allFieldsError = false; emailError = false; showSuccessMessage = false; apiError = false; passwordsDontMatch = false;
  firstName: string; emailId: string; password: string; confirmPassword: string; emailRegistered = false;
  returnUrl: string; showPass = false; showConfirmPass = false;
  firstNameError = false; emailIdError = false; userPasswordError = false; confirmPasswordError = false;
  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute, private authenticateService: AuthenticateService,
    private commonService: CommonService) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/discussionboard';
  }

  registerUser() {
    const firstName = this.firstName;
    const emailId = this.emailId;
    const userPassword = this.password;
    const confirmPassword = this.confirmPassword;
    if (!firstName) {
      this.firstNameError = true;
      return;
    }
    if (!emailId) {
      this.emailIdError = true;
      return;
    }
    if (!userPassword) {
      this.userPasswordError = true;
      return;
    }
    if (!confirmPassword) {
      this.confirmPasswordError = true;
      return;
    }
    if (userPassword !== confirmPassword) {
      this.passwordsDontMatch = true;
      return;
    }
    this.firstNameError = false;
    this.emailIdError = false;
    this.userPasswordError = false;
    this.confirmPasswordError = false;
    this.passwordsDontMatch = false;
    this.allFieldsError = false;
    const regex = new RegExp('^([a-zA-Z0-9_.-]+)@([a-zA-Z0-9_.-]+)\\.([a-zA-Z]{2,5})$');
    if (!regex.test(emailId)) {
      this.emailError = true;
      return;
    } else {
      this.emailError = false;
      const dataToSend = {
        first_name: firstName,
        password: userPassword,
        mail: emailId,
        email: emailId
      };
      this.authService.registerUser(dataToSend)
        .then((res: any = {}) => {
          if (res.success) {
            this.firstName = '';
            this.emailId = '';
            this.password = '';
            this.showSuccessMessage = true;
            if (this.returnUrl !== '/') {
              this.authenticateService.setToken(res.data.token);
              this.commonService.setUId(res.data.user_details.uid);
              this.commonService.setFirstName(res.data.user_details.first_name);
              this.router.navigateByUrl(this.returnUrl);
            } else {
              this.router.navigateByUrl(this.returnUrl);
            }
          }
        }, err => {
          if (err.error.data.mail[0] === 'The mail has already been taken.') {
            this.emailRegistered = true;
          } else {
            this.apiError = true;
            console.log('Error Registering: ', err);
          }
        });
    }
  }

  showPassword(param: string) {
    if (param === 'pass') {
      this.showPass = !this.showPass;
    } else {
      this.showConfirmPass = !this.showConfirmPass;
    }
  }

}
