import { Component, OnInit } from '@angular/core';

import { ApplicationConstant } from '../../constant/app.constant';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormService } from 'src/app/services/form.service';
import { ApplicationNotifierService } from 'src/app/shared/component/application-notifier/application-notifier.service';
import { CommonService } from 'src/app/services/common.service';
import { ApplicationNotifierConstant } from 'src/app/shared/constant/notifier.constant';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss'],
})
export class ChangePasswordDialogComponent implements OnInit {

  applicationConstant = ApplicationConstant;
  applicationNotifierConstant = ApplicationNotifierConstant;
  changePasswordForm: FormGroup;
  IsResetFormValid = true;
  errorMessage: any;

  constructor(private fb: FormBuilder,
              private formService: FormService,
              public commonService: CommonService,
              private applicationNotifierService: ApplicationNotifierService) {}

  ngOnInit(): void {
    this.changePasswordForm = this.fb.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required, Validators.minLength(4)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(4)]],
    });
  }

  /*
  update password
  */
  doUpdatePassword(form) {
    if (form.valid) {
      this.IsResetFormValid = true;
      if (form.get('newPassword').value === form.get('confirmPassword').value) {
        this.formService.saveDetails(`${this.applicationConstant.endpoint.CHANGE_PASSWORD_URL}`,
          this.changePasswordForm.value).subscribe((data) => {
              if ( data.success === true){
                this.applicationNotifierService.getNotifier(this.applicationNotifierConstant.password_updated);
              } else{
                this.applicationNotifierService.getNotifier(this.applicationNotifierConstant.invalid_current_password);
              }
            },
            (err) => {
              if (err.error.message) {
                this.errorMessage = err.error.message;
                this.IsResetFormValid = false;
              }
            }
          );
      } else {
        this.applicationNotifierService.getNotifier(
          this.applicationNotifierConstant.password_not_matched
        );
      }
    } else {
      this.IsResetFormValid = false;
    }
  }
}
