import { Injectable } from '@angular/core';
import { ApplicationConstant } from '../shared/constant/app.constant';
import { BaseService } from '../services/base.service';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  encryptDetails = {
    uid: null,
    type: null
  };

  constructor(private baseService: BaseService) { }

  /**
   * Get the user details.
   *
   * @param id of the user.
   */
  getUser(id: number) {
    return this.baseService.get(`${ApplicationConstant.endpoint.USER_DETAILS_URL}/${id}`)
      .toPromise()
      .then(response => response)
      .catch();
  }

  /**
   * Get the user profile image.
   * @param id of the user.
   */
  getProfileImage(id: number) {
    return this.baseService.get(`${ApplicationConstant.endpoint.USER_PROFILE_IMAGE_URL}?uid=${id}`)
      .toPromise()
      .then(response => response)
      .catch();
  }
}
