<div class="view-enlarge" *ngIf="showCkEditorViewer">
  <span class="material-icons" (click)="showCkEditorModal()">
    remove_red_eye
  </span>
</div>
<ckeditor [ngModel]="ckEditorModel"
          #ckEditor="ngModel"
          (ready)="onCkEditorReady($event)"
          [editor]="editor"
          name="ckEditor"
          (ngModelChange)="onModelChange($event)"
          (blur)="onTouch($event)"
          [config]="config">
</ckeditor>
