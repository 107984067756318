import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './guard/auth.guard';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './shared/component/page-not-found/page-not-found.component';
import { RegisterComponent } from './register/register.component';
import { ResetPasswordComponent } from './forgot-password/reset-password/reset-password.component';
import { CkEditorDiscussionComponent } from './ck-editor-discussion/ck-editor-discussion.component';
import { CkEditorSuccessComponent } from './ck-editor-success/ck-editor-success.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () => import('./admin-dashboard/admin-dashboard.module')
      .then(m => m.AdminDashboardModule)
  },
  {
    path: 'bursary',
    canActivate: [AuthGuard],
    loadChildren: () => import('./bursary-application/bursary-application.module')
      .then(m => m.BursaryApplicationModule)
  },
  {
    path: 'discussionboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./dashboard/dashboard.module')
      .then(m => m.DashboardModule)
  },
  {
    path: 'exemption',
    canActivate: [AuthGuard],
    loadChildren: () => import('./credit-exemption-application/credit-exemption-application.module')
      .then(m => m.CreditExemptionApplicationModule)
  },
  { path: 'login', component: LoginComponent },
  {
    path: 'quick-application',
    canActivate: [AuthGuard],
    loadChildren: () => import('./quick-application/quick-application.module')
      .then(m => m.QuickApplicationModule)
  },
  { path: 'register', component: RegisterComponent },
  { path: 'reset-password/:token', component: ResetPasswordComponent },
  { path: 'ck-editor-discussion', component: CkEditorDiscussionComponent },
  { path: 'ck-editor-success', component: CkEditorSuccessComponent },
  { path: '404', component: PageNotFoundComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
