<div class="athena-header col-md-12">
  <div class="logo col-2"
       (click)="redirectToHome()">
    <img src="assets/uniathena-logo.svg" />
  </div>
    <div class="navigation col-10">
    <div class="mobile-menu-icon float-right d-block d-md-none"
         (click)="toggleMenu(false)">
      <span></span>
    </div>
  </div>
</div>



