import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordDialogComponent } from 'src/app/shared/component/change-password-dialog/change-password-dialog.component';
import { AuthenticateService } from '../../services/authenticate.service';
import { CommonService } from '../../services/common.service';
import { HeaderService } from '../header.service';
import { ApplicationConstant } from '../../shared/constant/app.constant';
import { APP_CONFIGURATION, APP_URL } from '../../../environments/environment';
import { forkJoin, of } from 'rxjs';
import { BaseService } from 'src/app/services/base.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-academic-header',
  templateUrl: './academic-header.component.html',
  styleUrls: ['./academic-header.component.scss']
})
export class AcademicHeaderComponent implements OnInit, OnChanges {

  @Input() currentUrl: string;
  applicationConstant = ApplicationConstant;
  firstName: string;
  isDiscussionBoard = false;
  mobileViewMenu = false;
  showNavigation = false;
  userDetails: any;
  userProfileImage: any;

  constructor(private authenticateService: AuthenticateService,
              public commonService: CommonService,
              private headerService: HeaderService,
              public matDialog: MatDialog,
              private baseService: BaseService,
              private router: Router) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (window.innerWidth <= 768) {
        this.mobileViewMenu = true;
        this.showNavigation = false;
      }
    });
  }

  ngOnChanges(): void {
    this.setHeaderContent();
  }

  /**
   * Toggle the menu for show a/hide.
   *
   * @param toggleState true/false.
   */
  toggleMenu(toggleState: boolean) {
    this.showNavigation = toggleState;
  }

  /**
   * Set the header content.
   */
  setHeaderContent() {
    const uid = this.commonService.getUId();
    this.currentUrl = this.commonService.currentUrl;
    if (this.currentUrl) {
      if (this.authenticateService.isTokenAvailable()) {
        this.getProfileImage(uid);
        this.getUserData(uid);
        this.isDiscussionBoard  = this.currentUrl.indexOf(ApplicationConstant.appRouting.DISCUSSION_BOARD) > -1;
        this.showNavigation = this.currentUrl.indexOf(ApplicationConstant.appRouting.DISCUSSION_BOARD) > -1;
      }
    }
  }

  /**
   * Get user data.
   *
   * @param id of the user.
   */
  getUserData(id: number) {
    this.headerService.getUser(id).then((res: any = {}) => {
      this.userDetails = res;
      this.firstName = this.userDetails.first_name || 'Student';
      if (this.userDetails.changed_role_id.indexOf(APP_CONFIGURATION.GUEST) > -1) {
        this.encryptUIDAndType();
      }
    }, err => {
      console.log('Error Registering: ', err);
    });
  }

  /**
   * Open dashboard.
   */
  openDashboard() {
    let url = `${APP_URL.OLD_LMS}StudentHome?UserId=${this.userDetails.external_uid}`;
    if (this.userDetails.changed_role_id.indexOf(APP_CONFIGURATION.GUEST) > -1) {
      url = `${APP_URL.OLD_LMS}FreetrialHome?uid=${this.headerService.encryptDetails.uid}&type=${this.headerService.encryptDetails.type}`;
    }
    window.location.href = url;
  }

  /**
   * Go to profile.
   */
  goToProfile() {
    if (this.currentUrl.indexOf('admin') > -1 || this.currentUrl.indexOf('login') > -1 || this.currentUrl.indexOf('discussionboard') > -1
      || this.currentUrl.indexOf('quick-application') > -1 || this.currentUrl.indexOf('bursary') > -1
      || this.currentUrl.indexOf('exemption') > -1) {
      window.location.href = 'profile';
    } else {
      const dialogRef = this.matDialog.open(ChangePasswordDialogComponent, {
        width: '400px',
        height: 'auto',
        panelClass: 'ath-panel-dialog',
        data: {
          data: '',
        },
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
        }
      });
    }

  }

  /**
   * User profile.
   */
  profile() {
    if (window.innerWidth <= 768) {
      this.toggleMenu(true);
    }
    this.router.navigateByUrl(this.applicationConstant.appRouting.STUDENT_PROFILE);
  }

  /**
   * Open project details.
   */
  openProjectDetails() {
    window.location.href = `${APP_URL.OLD_LMS}Projects?UserId=${this.userDetails.external_uid}`;
  }

  /**
   * Open online classes.
   */
  openOnlineClasses() {
    window.location.href = `${APP_URL.OLD_LMS}MyOnlineClass?UserId=${this.userDetails.external_uid}`;
  }

  /**
   * Open application.
   */
  openApplication() {
    if (window.innerWidth <= 768) {
      this.toggleMenu(true);
    }
    this.router.navigateByUrl(this.applicationConstant.appRouting.QUICK_APPLICATION_USER_DETAILS);
  }

  /**
   * Redirect to the home.
   */
  redirectToHome() {
    if (window.innerWidth <= 768) {
      this.toggleMenu(true);
    }
    if (window.location.href.indexOf('discussion')>-1) {
      this.router.navigateByUrl(this.applicationConstant.appRouting.DISCUSSION_BOARD);
      return;
    }
  }

  /**
   * Open profile.
   */
  openProfile() {
    window.location.href = `${APP_URL.OLD_LMS}MyProfile?UserId=${this.userDetails.external_uid}`;
  }

  /**
   * Change password for the user.
   */
  changePassword() {
    const dialogRef = this.matDialog.open(ChangePasswordDialogComponent, {
      width: '400px',
      height: 'auto',
      panelClass: 'ath-panel-dialog',
      data: {
        data: '',
      },
      autoFocus: false,
      
    });
    }

  /**
   * Get the profile image.
   *
   * @param id of the user.
   */
  getProfileImage(id: number) {
    this.headerService.getProfileImage(id).then((res: any = {}) => {
      this.userProfileImage = res.data;
    }, err => {
      console.log('Error Registering: ', err);
    });
  }

  /**
   * Logout from the application.
   */
  logout() {
    localStorage.clear();
    this.delete_cookie('hideOnBoarding');
    const url = this.currentUrl;
    this.commonService.setUId(0);
    this.commonService.setFirstName('');
    if (url && url.indexOf('admin') > -1) {
      this.router.navigateByUrl(this.applicationConstant.appRouting.LOGIN);
    } else {
      window.location.href = `${APP_URL.OLD_LMS}${this.applicationConstant.endpoint.OLD_LMS_LOGIN_URL}`;
    }
  }

  /**
   * Go to profile pic.
   * @param url
   */
  getProfilePic(url: string) {
    if (url) { return url; }
    return 'assets/user-img-pic-latest.svg';
  }

  /**
   * Delete cookie for the user.
   * @param name
   */
  delete_cookie(name: string) {
    document.cookie = name + '=; domain=.athena.edu;Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  /**
   * Get encryted value for userid and type (athena)
   */
  private encryptUIDAndType() {
    if (this.headerService.encryptDetails.uid && this.headerService.encryptDetails.type) {
      return;
    }
    forkJoin([
      this.baseService.getEncryptData(`${this.userDetails.external_uid}/Encript`).pipe(catchError(err => of(console.error(err)))),
      this.baseService.getEncryptData('Athena/Encript').pipe(catchError(err => of(console.error(err))))
      ])
      .subscribe(([encryptUID, encryptType]) => {
        this.headerService.encryptDetails.uid = encryptUID;
        this.headerService.encryptDetails.type = encryptType;
    });
  }

}
