import { Injectable } from '@angular/core';
import { ApplicationConstant } from '../shared/constant/app.constant';
import { BaseService } from './base.service';
import { APP_URL } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private appliedCourseId: number;
  private loginProviderData: string;
  private uid: number;
  currentUrl: string;
  firstName: string;
  userDetails: any;
  userLogged: any;
  applicationConstant = ApplicationConstant;

  constructor(private baseService: BaseService) {
  }

  /**
   * Get the course information.
   */
  get course() {
    if (this.appliedCourseId) {
      return this.appliedCourseId;
    } else {
      this.appliedCourseId = Number(localStorage.getItem(ApplicationConstant.storageKeys.COURSE_ID));
      return this.appliedCourseId;
    }
  }

  /**
   * Set the course information.
   *
   * @param courseId course id for applied application
   */
  set course(courseId: number) {
    localStorage.setItem(ApplicationConstant.storageKeys.COURSE_ID, courseId.toString());
    this.appliedCourseId = courseId;
  }

  /**
   * Set the login provider data.
   *
   * @param provider for login
   */
  set loginProvider(provider: string) {
    localStorage.setItem(ApplicationConstant.storageKeys.LOGIN_PROVIDER, provider);
    this.loginProviderData = provider;
  }

  /**
   * Get the user
   * @param isApiCallRequest true/false
   */
  getUser(isApiCallRequest: boolean): Promise<any> {
    return new Promise(resp => {
      if (this.userDetails && !isApiCallRequest) {
        resp(this.userDetails);
      } else {
        this.baseService.get(`${ApplicationConstant.endpoint.USER_DETAILS_URL}/${this.getUId()}`)
          .subscribe(userDetails => {
            if (userDetails) {
              this.userDetails = userDetails;
              resp(this.userDetails);
            } else {
              throw Error('No data response');
            }
            }, error => {
            console.log('error', error);
        });
      }
    });
  }

  /**
   * Get the user details.
   */
  get userId() {
    if (this.uid) {
      return this.uid;
    } else {
      const uid = localStorage.getItem(ApplicationConstant.storageKeys.U_ID);
      this.uid = uid ? Number(uid) : null;
      return this.uid;
    }
  }

  /**
   * Set the user details.
   *
   * @param uid
   */
  set userId(uid: number) {
    localStorage.setItem(ApplicationConstant.storageKeys.U_ID, uid.toString());
    this.uid = uid;
  }

  /**
   * Get uid.
   */
  getUId() {
    if (this.uid) {
      return this.uid;
    } else {
      const uid = Number(localStorage.getItem(ApplicationConstant.storageKeys.U_ID));
      this.uid = uid > 0 ? uid : null;
      return this.uid;
    }
  }

  /**
   * Set uid.
   * @param uid unique id for user.
   */
  setUId(uid: number) {
    localStorage.setItem(ApplicationConstant.storageKeys.U_ID, uid.toString());
    this.uid = uid;
  }

  /**
   * Set categories.
   * @param data unique id for user.
   */
   setCategories(data: any) {
    localStorage.setItem(ApplicationConstant.storageKeys.CATEGORY_NAMES, JSON.stringify(data));
  }

  /**
   *
   *
   * @param {*} data
   * @param {number} pageNo
   * @memberof CommonService
   */
  setDiscussions(data: any, pageNo: number) {
    localStorage.setItem(`${ApplicationConstant.storageKeys.DISCUSSIONS}_${pageNo}`, JSON.stringify(data));
  }

  /**
   *
   *
   * @param {number} pageNo
   * @memberof CommonService
   */
  setPagesData(pageNo: number) {
    localStorage.setItem(ApplicationConstant.storageKeys.PAGE_NO, pageNo.toString());
  }

  clearLocalStorage() {
    for (const key in localStorage){
      if (key.indexOf('discussions_') > -1) {
        localStorage.removeItem(key);
      }
    }
  }

  /**
   *
   *
   * @return {*}
   * @memberof CommonService
   */
  getCategories() {
    const data = localStorage.getItem(ApplicationConstant.storageKeys.CATEGORY_NAMES);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  }

  /**
   * Get the first name.
   */
  getFirstName() {
    if (this.firstName) {
      return this.firstName;
    } else {
      this.firstName = localStorage.getItem(ApplicationConstant.storageKeys.FIRST_NAME);
      return this.firstName;
    }
  }

  /**
   * Set the first name.
   * @param firstName name of the user.
   */
  setFirstName(firstName: string) {
    localStorage.setItem(ApplicationConstant.storageKeys.FIRST_NAME, firstName);
    this.firstName = firstName;
  }

  /**
   * Set Countries
   * @param data countries
  */
  setCountries(data: any) {
    localStorage.setItem(ApplicationConstant.storageKeys.COUNTRIES, JSON.stringify(data));
  }

  /**
   * Get Countries
  */
  getCountries() {
    return JSON.parse(localStorage.getItem(ApplicationConstant.storageKeys.COUNTRIES));
  }

  /**
   * Navigate to External link.
   *
   * @param url of the page.
   */
  navigateWebsiteLink(url) {
    window.location.href = `${APP_URL.WEBSITE}${url}`;
  }

  /**
   * Navigate to External link.
   *
   * @param url of the page.
   */
  navigateOLDLMSLink(url) {
    window.location.href = `${APP_URL.OLD_LMS}${url}`;
  }

  /**
   * Trim empty spaces in a string or numbers
   */
  trimEmptySpaces(value: any) {
    return value.replace(/\s/g, '');
  }
  
}
