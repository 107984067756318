import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth-service';
import { AuthenticateService } from '../services/authenticate.service';
import { CommonService } from '../services/common.service';
import { ApplicationConstant } from '../shared/constant/app.constant';
import { APP_CONFIGURATION, APP_URL } from '../../environments/environment';
import { FormService } from '../services/form.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  applicationConstant = ApplicationConstant;
  locationDetails = ApplicationConstant.DEFAULT_COUNTRY;
  emailId: string;
  userPassword: string;
  returnUrl: string;
  unRegisteredUser = false;
  invalidPassword = false;
  showNoAccessMessage = false;
  showPass = false;
  fillAllFieldsError = false;
  inCorrectEmail = false;
  apiError = false;

  constructor(private authService: AuthService,
              private authenticateService: AuthenticateService,
              private commonService: CommonService,
              public formService: FormService,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.getCountryCode();
    localStorage.clear();
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/discussionboard';
  }

  userLogin() {
    const emailId = this.emailId;
    const userPassword = this.userPassword;
    if (!emailId || !userPassword) {
      this.fillAllFieldsError = true;
      return;
    }
    this.fillAllFieldsError = false;
    const regex = new RegExp('^([a-zA-Z0-9_.-]+)@([a-zA-Z0-9_.-]+)\\.([a-zA-Z]{2,5})$');
    if (!regex.test(emailId)) {
      this.inCorrectEmail = true;
    } else {
      this.inCorrectEmail = false;
      const dataToSend = {
        mail: emailId,
        password: userPassword
      };
      this.authService.authenticateUser(dataToSend).then((res: any = {}) => {
          if (res.success) {
            if (this.returnUrl.indexOf('admin') > 0 && res.data.user_details.changed_role_id
              && res.data.user_details.changed_role_id.indexOf(APP_CONFIGURATION.ADMIN) === -1) {
              this.showNoAccessMessage = true;
              return;
            } else if (res.data.user_details.changed_role_id &&
              res.data.user_details.changed_role_id.indexOf(APP_CONFIGURATION.ADMIN) > -1 &&
              this.returnUrl.indexOf('comments') === -1) {
              this.returnUrl = this.applicationConstant.appRouting.ADMIN_DASHBOARD;
            }
            this.authenticateService.setToken(res.data.token);
            this.commonService.setUId(res.data.user_details.uid);
            this.commonService.setFirstName(res.data.user_details.first_name);
            this.commonService.loginProvider = this.applicationConstant.LOGIN_PROVIDERS.PASSWORD;
            this.router.navigateByUrl(this.returnUrl);
          }
        }, err => {
          if (err && err.error.data.User && err.error.data.User[0] === 'Invalid User') {
            this.unRegisteredUser = true;
          } else if (err && err.error.data.password && err.error.data.password[0] === 'The password is invalid.') {
            this.invalidPassword = true;
          } else if (err && err.error.data.mail && err.error.data.mail[0] === 'The selected mail is invalid.') {
            this.unRegisteredUser = true;
          } else {
            this.apiError = true;
            console.log('Error Registering: ', err);
          }
        });
    }
  }

  registerPage() {
    if (this.returnUrl !== '/') {
      const url = `/register?returnUrl=${encodeURIComponent(this.returnUrl)}`;
      this.router.navigateByUrl(url);
    } else {
      this.router.navigateByUrl('/register');
    }
  }

  openRegisterLink() {
    window.location.href = `${APP_URL.WEBSITE}registration`;
  }

  showPassword() {
    this.showPass = !this.showPass;
  }

   /**
   * Get the country code.
   */
    getCountryCode() {
      this.formService.getDetailsFromThirdParty(`${APP_URL.IP_GEOLOCATION}`, null).then((data: any) => {
        if (data) {
          this.formService.getDetails(`${ApplicationConstant.endpoint.GET_COUNTRY_INFO}/${data.countryCode}`)
            .subscribe(res => {
              if (res) {
                this.locationDetails = {
                  countryId : res?.country_id,
                  countryCode : res?.iso3,
                  countryContactCode : res?.phonecode ? '+' + res?.phonecode : ''
                };
              }
            }, error => {
              console.error(error);
            });
        }
      });
    }

}
