import { Component, Input, OnChanges } from '@angular/core';
import { ApplicationConstant } from '../shared/constant/app.constant';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnChanges {

  @Input() currentURL: string;
  applicationConstant = ApplicationConstant;
  showPlainHeader = false;
  showAcademicHeader = false;
  headerConfig;

  constructor(private commonService: CommonService) {
    this.headerConfig = this.setHeaderConfig();
  }

  ngOnChanges() {
    if (this.currentURL) {
      this.setHeaderContent(this.currentURL);
    }
  }

  /**
   * Set the header configuration.
   */
  setHeaderConfig() {
    return  {
      academicHeader: [
        ApplicationConstant.appRouting.ADMIN_APPLICATION,
        ApplicationConstant.appRouting.DISCUSSION_BOARD,
        ApplicationConstant.appRouting.PAYMENT_DETAILS,
        ApplicationConstant.appRouting.QUIZ_ASSESSMENT,
        ApplicationConstant.appRouting.STUDENT_DASHBOARD,
        ApplicationConstant.appRouting.STUDENT_PROFILE
      ]
    }
  }

  /**
   * Show header for the application.
   *
   * @param showPlainHeader true/false
   * @param showAcademicHeader true/false
   */
  setHeaders(showPlainHeader: boolean, showAcademicHeader: boolean) {
    this.showPlainHeader = showPlainHeader;
    this.showAcademicHeader = showAcademicHeader;
  }

  /**
   * Set the header content.
   *
   * @param url request url
   */
  setHeaderContent(url: string) {
    this.currentURL = url;
    this.commonService.currentUrl = url;
    if (this.headerConfig.academicHeader.some(value => url.indexOf(value) > -1)) {
      this.setHeaders(false, true);
    } else {
      this.setHeaders(true, false);
    }
  }
}
