import { AbstractControl, ValidationErrors } from '@angular/forms';

export class DescriptionValidator {
    static descriptionMaxContent(control: AbstractControl): ValidationErrors | null {
        if (control.value && (control.value as string).replace(/\r?\n|\r/g, '').length > 500) {
            return {descriptionMaxContent: true};
        }
        return null;
    }
}

export class MetaDescriptionValidator {
    static metaDescriptionMaxContent(control: AbstractControl): ValidationErrors | null {
        if (control.value && (control.value as string).replace(/\r?\n|\r/g, '').length > 240) {
            return {metaDescriptionMaxContent: true};
        }
        return null;
    }
}

export class MetaTitleValidator {
    static metaTitleMaxContent(control: AbstractControl): ValidationErrors | null {
        if (control.value && (control.value as string).replace(/\r?\n|\r/g, '').length > 100) {
            return {metaTitleMaxContent: true};
        }
        return null;
    }
}

export class ReasonToJoinOrExpectationValidator {
    static reasonToJoinOrExpectationMaxContent(control: AbstractControl): ValidationErrors | null {
        if (control.value && (control.value as string).split(/\b\W+\b/).length > 50) {
            return {reasonToJoinOrExpectationMaxContent: true};
        }
        return null;
    }
}
