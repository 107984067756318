<div class="container-fluid form-header">
  <div class="header-container">
    <h1 class="header-text">Login</h1>
  </div>
</div>
<div class="container login">
    <div class="col-md-12 row user-details">
      <div class="col-md-5 logo">
        <div class="item-1">
        <img src="../../assets/uniathena-logo.svg" class="logo-img"/>
      </div>
    </div>
    <div class="col-md-7 user-info">
      <div class="username">
        <label>Email</label>
        <input
          [ngClass]="{ invalid: inCorrectEmail }"
          [(ngModel)]="emailId"
          placeholder="Email"
          type="email"
          (keyup.enter)="userLogin()"
          required
        />
      </div>
      <div class="password">
        <label>Password</label>
        <input
          [(ngModel)]="userPassword"
          [ngClass]="{ invalid: invalidPassword }"
          placeholder="Password"
          [type]="showPass ? 'text' : 'password'"
          (keyup.enter)="userLogin()"
          required
        /><span class="eye"
          ><img src="assets/eye.svg" (click)="showPassword()"
        /></span>
      </div>
      <p class="error" *ngIf="fillAllFieldsError">
        Please enter all the fields
      </p>
      <p class="error" *ngIf="showNoAccessMessage">
        We are sorry, but you dont have access to this page!!
      </p>
      <p class="error" *ngIf="inCorrectEmail">Please enter a valid Email</p>
      <p class="error" *ngIf="invalidPassword">
        Please enter the correct password!
      </p>
      <p class="error" *ngIf="apiError">
        Something went wrong!! Please try later.
      </p>
      <p class="not-registered" *ngIf="unRegisteredUser">
        Ohh!! Looks like you have not registered with us. <br />
        Please register by clicking <a (click)="openRegisterLink()">here.</a>
      </p>
      <div class="col-md-12 login-new row">
        <div class="col-md-8 forgot-password">
          <!-- <p>New User? <span (click)="registerPage()">Sign Up</span></p>
          <p>Forgot Password? <span>Click here</span></p> -->
        </div>
        <div class="col-md-4 submit">
          <button (click)="userLogin()">Login</button>
        </div>
      </div>
    </div>
  </div>
</div>
